const safeEnv = (key: string) => {
  const value = process.env[key]
  if (value === undefined) throw new Error(`Missing env variable ${key}`)
  return value
}

// const boolean = (value: string | undefined) => {
//   return Boolean(value && value.toLowerCase && value.toLowerCase() === 'true')
// }

export const defaultConfig = {
  baseUrl: safeEnv('REACT_APP_BASE_URL'),
  translationPath: safeEnv('REACT_APP_TRANSLATION_PATH'),
}

export type AppConfig = typeof defaultConfig

export default defaultConfig

import ApiService from './apiService'
import { Stores } from '@customTypes/store'
import config from '@config/index'

const apiURL =
  config.baseUrl && config.baseUrl.trim().endsWith('/') ? config.baseUrl : `${config.baseUrl}/`
const apiService = new ApiService(apiURL)

class StoresApiService {
  private api: ApiService
  constructor(api: ApiService) {
    this.api = api
  }

  public getStores = (url: string) => {
    return this.api.get<Stores>(url)
  }
}

export const storesApiService = new StoresApiService(apiService)
